/* eslint-disable import/no-anonymous-default-export */
import React from "react";
import SEO from "../components/seo";
import Layout from "../containers/layout/layout";
import Banner from "../containers/privacy/banner";
import Content from "../containers/privacy/content";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Privacy" />
    <Banner />
    <Content />
  </Layout>
);
export default PrivacyPage;
